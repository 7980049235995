<template>
  <a-drawer width="800" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="模版类型" prop="type" v-show="false">
            <a-select
              v-model="form.type"
              placeholder="请选择"
              style="width: 240px;"
              allow-clear
              disabled>
              <a-select-option v-for="(item,key) in [{name:'系统模板',key:0},{name:'DIY模板',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="模版名称" prop="name">
            <a-input v-model="form.name" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="设备型号" prop="deviceModelIds">
            <a-select
              v-model="form.dtIds"
              mode="multiple"
              optionFilterProp="children"
              placeholder="请选择"
              style="width: 240px;"
              @change="handleChange">
              <a-select-option v-for="(item,key) in deviceTypeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="年份" prop="year">
            <a-date-picker
              v-model="form.year"
              placeholder="请输入"
              mode="year"
              :allowClear="false"
              :open="yearShowOne"
              valueFormat="YYYY"
              format="YYYY"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"
              :disabledDate="yearDisabledDate"
              style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="背景图片宽" prop="bgPicLong">
            <a-input v-model="form.bgPicLong" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="背景图片高" prop="bgPicWide">
            <a-input v-model="form.bgPicWide" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="背景X坐标" prop="bgX">
            <a-input v-model="form.bgX" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="背景Y坐标" prop="bgY">
            <a-input v-model="form.bgY" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="封面图" prop="picPathStr">
            <div class="ant-upload-preview" @click="$refs.upImg.edit(form.picPathStr)" >
              <div class="mask" :style="form.picPathStr ? 'opacity: 0' : 'opacity: 1'">
                <a-icon type="plus" />
              </div>
              <img :src="form.picPathStr" v-show="form.picPathStr"/>
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item label="备注" prop="remark">
            <a-input v-model="form.remark" placeholder="请输入"/>
          </a-form-model-item> -->
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="方向" prop="direction">
            <a-select v-model="form.direction" placeholder="请选择" style="width: 240px;" allow-clear>
              <a-select-option v-for="(item,key) in [{name:'横屏',key:0},{name:'竖屏',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="消息字体颜色" prop="msgFontColor">
            <a-select v-model="form.msgFontColor" placeholder="请选择" style="width: 240px;" allow-clear>
              <a-select-option v-for="(item,key) in [{name:'红色',key:0},{name:'黑色',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="消息显示方式" prop="msgMethod">
            <a-select v-model="form.msgMethod" placeholder="请选择" style="width: 240px;" allow-clear>
              <a-select-option v-for="(item,key) in [{name:'竖屏',key:0},{name:'横屏',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="消息图片宽" prop="msgPicLong">
            <a-input v-model="form.msgPicLong" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="消息图片高" prop="msgPicWide">
            <a-input v-model="form.msgPicWide" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="消息X坐标" prop="msgX">
            <a-input v-model="form.msgX" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="消息Y坐标" prop="msgY">
            <a-input v-model="form.msgY" placeholder="请输入" style="width: 240px;"/>
          </a-form-model-item>
          <a-form-model-item label="日历上传(压缩包)" prop="calendarPath" v-if="tempType == 1">
            <file-upload v-model="form.calendarPath" type="file" accept=".zip,.rar"></file-upload>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <up-img-modal ref="upImg" :width="400" :height="400" @ok="setImg"/>
  </a-drawer>
</template>

<script>
import { pageDeviceType } from '@/api/valve/deviceType'
import { getTemplate, addTemplate, updateTemplate } from '@/api/valve/template'
import upImgModal from '@/components/upImgModal'
import moment from 'moment'
export default {
  name: 'CreateForm',
  props: {
    tempType: {
      type: Number,
      default: 0
    }
  },
  components: {
    upImgModal
  },
  data() {
    return {
      yearShowOne: false,
      previewVisible: false,
      fileList: [],
      submitLoading: false,
      deviceTypeList: [],
      formTitle: '',
      // 表单参数
      form: {
        id: 0,
        year: '',
        name: '',
        picPath: '',
        picPathStr: '',
        remark: '',
        msgFontColor: 0,
        msgMethod: 0,
        msgPicLong: '',
        msgPicWide: '',
        msgX: '',
        msgY: '',
        bgPicLong: '',
        bgPicWide: '',
        bgX: '',
        bgY: '',
        type: 0,
        direction: 0,
        status: 0,
        dtIds: [],
        deviceModelIds: '',
        calendarPath: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '模版名称不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择模版类型', trigger: ['blur', 'change'] }
        ],
        deviceModelIds: [
          { required: true, message: '请选择设备型号', trigger: ['blur', 'change'] }
        ],
        msgFontColor: [
          { required: true, message: '请选择消息字体颜色', trigger: ['blur', 'change'] }
        ],
        msgMethod: [
          { required: true, message: '请选择消息显示方式', trigger: ['blur', 'change'] }
        ],
        msgPicLong: [
          { required: true, message: '消息图片宽不能为空', trigger: 'blur' },
          { validator: this.validateMsgPicLong, trigger: 'blur' }
        ],
        msgPicWide: [
          { required: true, message: '消息图片高不能为空', trigger: 'blur' },
          { validator: this.validateMsgPicWide, trigger: 'blur' }
        ],
        msgX: [
          { required: true, message: '消息X坐标不能为空', trigger: 'blur' },
          { validator: this.validateMsgX, trigger: 'blur' }
        ],
        msgY: [
          { required: true, message: '消息Y坐标不能为空', trigger: 'blur' },
          { validator: this.validateMsgY, trigger: 'blur' }
        ],
        calendarPath: [
          { required: true, message: '日历上传(压缩包)不能为空', trigger: 'change' }
        ],
        bgPicLong: [
          { required: true, message: '背景图片宽不能为空', trigger: 'blur' }
        ],
        bgPicWide: [
          { required: true, message: '背景图片高不能为空', trigger: 'blur' }
        ],
        bgX: [
          { required: true, message: '背景X坐标不能为空', trigger: 'blur' },
          { validator: this.validateNum, trigger: 'blur' }
        ],
        bgY: [
          { required: true, message: '背景Y坐标不能为空', trigger: 'blur' },
          { validator: this.validateNum, trigger: 'blur' }
        ],
        direction: [
          { required: true, message: '方向不能为空', trigger: ['blur', 'change'] }
        ],
        year: [
          { required: true, message: '年份不能为空', trigger: ['change', 'blur'] }
        ],
        picPathStr: [
          { required: true, message: '封面图不能为空', trigger: 'blur' }
        ]
      },
      projectList: []
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    yearDisabledDate (current) {
      const startYear = moment().startOf('year').add(-2, 'year')
      const endYear = moment().startOf('year').add(98, 'year')
      if (!current) {
        return false
      }
      const year = current.year()
      return year < startYear.year() || year > endYear.year()
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      // status是打开或关闭的状态
      this.yearShowOne = !this.yearShowOne
    },
    // 得到年份选择器的值
    panelChangeOne(value, mode) {
      console.log(value, 'valuye', mode)
      this.form.year = value.format('YYYY')
      this.yearShowOne = !this.yearShowOne
    },
    handleChange(e) {
      console.log(e)
      this.form.deviceModelIds = e.toString()
    },
    validateNum (rule, value, callback) {
      // console.log(rule, value)
      if (value % 8 === 0) {
        callback()
      } else {
        callback(new Error('请输入8的倍数'))
      }
    },
    // 消息图片宽判断
    validateMsgPicLong (rule, value, callback) {
      if (this.form.direction == 1) {
        if ((parseFloat(value) + parseFloat(this.form.msgX)) > 480) {
          callback(new Error('消息图片宽+消息X坐标不能大于480'))
        } else {
          callback()
        }
      } else {
        if ((parseFloat(value) + parseFloat(this.form.msgX)) > 800) {
          callback(new Error('消息图片宽+消息X坐标不能大于800'))
        } else {
          callback()
        }
      }
    },
    // 消息图片高判断
    validateMsgPicWide (rule, value, callback) {
      if (this.form.direction == 1) {
        if ((parseFloat(value) + parseFloat(this.form.msgY)) > 800) {
          callback(new Error('消息图片高+消息Y坐标不能大于800'))
        } else {
          callback()
        }
      } else {
        if ((parseFloat(value) + parseFloat(this.form.msgY)) > 480) {
          callback(new Error('消息图片高+消息Y坐标不能大于480'))
        } else {
          callback()
        }
      }
    },
    // 消息X坐标判断
    validateMsgX (rule, value, callback) {
      if (this.form.direction == 1) {
        if ((parseFloat(value) + parseFloat(this.form.msgPicLong)) > 480) {
          callback(new Error('消息X坐标+消息图片宽不能大于480'))
        } else if (value % 8 !== 0) {
          callback(new Error('请输入8的倍数'))
        } else {
          callback()
        }
      } else {
        if ((parseFloat(value) + parseFloat(this.form.msgPicLong)) > 800) {
          callback(new Error('消息X坐标+消息图片宽不能大于800'))
        } else if (value % 8 !== 0) {
          callback(new Error('请输入8的倍数'))
        } else {
          callback()
        }
      }
    },
    // 消息Y坐标判断
    validateMsgY (rule, value, callback) {
      if (this.form.direction == 1) {
        if ((parseFloat(value) + parseFloat(this.form.msgPicWide)) > 800) {
          callback(new Error('消息Y坐标+消息图片高不能大于800'))
        } else if (value % 8 !== 0) {
          callback(new Error('请输入8的倍数'))
        } else {
          callback()
        }
      } else {
        if ((parseFloat(value) + parseFloat(this.form.msgPicWide)) > 480) {
          callback(new Error('消息Y坐标+消息图片高不能大于480'))
        } else if (value % 8 !== 0) {
          callback(new Error('请输入8的倍数'))
        } else {
          callback()
        }
      }
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.$refs.form && this.$refs.form.clearValidate()
      this.reset()
    },
    // 表单重置
    reset(type) {
      this.formType = 1
      this.form = {
        id: 0,
        days: null,
        year: '',
        name: '',
        picPath: '',
        picPathStr: '',
        remark: '',
        msgFontColor: 0,
        msgMethod: 0,
        msgPicLong: '',
        msgPicWide: '',
        msgX: '',
        msgY: '',
        type: type,
        status: 0,
        dtIds: [],
        deviceModelIds: ''
      }
      pageDeviceType({ type: 0, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
        this.deviceTypeList = response.data.records
      })
      this.$forceUpdate()
    },
    /** 新增按钮操作 */
    handleAdd(type) {
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.reset(type)
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTemplate({ 'id': id }).then(response => {
        const info = response.data
        info.dtIds = info.deviceModelIds ? info.deviceModelIds.split(',') : []
        this.form = info
        this.open = true
        this.formTitle = '修改'
        this.fileList = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.picPathStr
        }]
      })
    },
    setImg (url) {
      console.log(url)
      this.form.picPath = url
      this.form.picPathStr = url
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateTemplate(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTemplate(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
